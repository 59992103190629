
import { useUserStore } from "@/store/modules/user";
function formatParams(paramData) {
    if (!paramData) return ''
    let data = paramData
    let arr = []
    for (let key in data) {
        arr.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    }
    return arr.join('&')
}
class Ajax {
    ajax(config) {
        let xhr = new XMLHttpRequest()
        let type = (config.type || 'get').toUpperCase()
        let contentType = config.contentType || 'application/x-www-form-urlencoded'
        const userStore = useUserStore();
        if (type === 'GET') {
            let params = formatParams(config.data)
            xhr.open('GET', params ? (config.url + '?' + params) : config.url, config.async || true)
            
            if (userStore.token) {
                xhr.setRequestHeader("Authorization", userStore.token)
            }
            if (userStore.userInfo&&userStore.userInfo.tenantId) {
                xhr.setRequestHeader("X-Tenant-Id", userStore.userInfo.tenantId)
            }
            xhr.send(null)
        } else {
            xhr.open('POST', config.url, config.async || true)
            xhr.setRequestHeader("Content-type", contentType)
            if (userStore.token) {
                xhr.setRequestHeader("Authorization", userStore.token)
            }
            if (userStore.userInfo&&userStore.userInfo.tenantId) {
                xhr.setRequestHeader("X-Tenant-Id", userStore.userInfo.tenantId)
            }
            if (contentType === 'application/x-www-form-urlencoded') {
                let params = formatParams(config.data)
                xhr.send(params)
            } else if (contentType === 'application/json') {
                xhr.send(JSON.stringify(config.data))
            }

        }
        xhr.onreadystatechange = function () {
            if (xhr.status == 200) {
                config.success && config.success(xhr.responseText)
            } else {
                config.error && config.error(xhr)
            }
        }
    }
    static getInstance() {
        if (Ajax.instance) {
            return Ajax.instance
        } else {
            Ajax.instance = new Ajax()
            return Ajax.instance
        }
    }
}
const instanse = Ajax.getInstance()
export { instanse }