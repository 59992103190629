<!-- eslint-disable no-unreachable -->
<template>
    <a-modal  :title="modalTitle" v-model:visible="step1Visible" @ok="handleNext" :maskClosable="false" :closable="false" @cancel="handleClose">
        <BasicForm
            v-if="visible2"
            ref="formRef"
            :labelCol="{ span: 5 }"
            :formItemsMap="formItemsMap"
            v-model:formData="formData"
            @change="changeForm"
        ></BasicForm>
        <div v-if="visible">
            <template v-if="data.identifier === 'GeneralWritePoints'">
                <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }" v-if="data && data.functionParameter">
                    <a-form-item :label="data.functionParameter[0].name">
                        <a-select v-model:value="data.general.writablePoint" placeholder="请选择" @change="changePoint"
                            style="width: 100%;">
                            <a-select-option v-for="item in data.functionParameter[0].valueDescription" :key="item.key"
                                :value="item.key">{{ item.value }}</a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item :label="data.functionParameter[1].name">
                        <a-input v-if="data.general && data.general.writablePointValueOption.dataType == '01'"
                            v-model:value="data.general.writablePointValue"
                            :maxlength="data.general.writablePointValueOption.valueDescription ? Number(data.general.writablePointValueOption.valueDescription[0].value) : null"
                            style="width: 100%;"></a-input>
                        <a-input-number style="width: 100%;"
                            v-else-if="data.general.writablePointValueOption.dataType == '02' || data.general.writablePointValueOption.dataType == '03'"
                            v-model:value="data.general.writablePointValue"
                            :min="findValue(data.general.writablePointValueOption.valueDescription, 'MIN', Number)"
                            :max="findValue(data.general.writablePointValueOption.valueDescription, 'MAX', Number)" />
                        <a-select v-else v-model:value="data.general.writablePointValue" placeholder="请选择"
                            @change="changePointValue" style="width: 100%;">
                            <a-select-option v-for="item in data.general.writablePointValueOption.valueDescription"
                                :key="item.key" :value="item.key">{{ item.value }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-form>
            </template>
            <template v-else>
                <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
                    <a-form-item v-for="item in data.functionParameter" :key="item.identifier" :label="item.name">
                        <a-input v-if="item.dataType == '01'" v-model:value="item.value" style="width: 100%;"
                            :maxlength="Number(item.valueDescription[0].value)" :disabled="item.checked"></a-input>
                        <a-input-number style="width: 100%;" v-else-if="item.dataType == '02' || item.dataType == '03'"
                            v-model:value="item.value" :min="findValue(item.valueDescription, 'MIN', Number)"
                            :max="findValue(item.valueDescription, 'MAX', Number)" :disabled="item.checked" />
                        <a-select v-else style="width: 100%;" v-model:value="item.value" placeholder="请选择"
                            :disabled="item.checked">
                            <a-select-option v-for="item in item.valueDescription" :key="item.key" :value="item.key">{{
            item.value }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-form>
            </template>
        </div>
        <div v-if="innerVisible">
            <div v-if="data.identifier === 'GeneralWritePoints'">即将执行开启设备服务（<span style="margin-right: 10px;">{{
            data.functionParameter[0].name }}：{{ data.general.writablePointValueOption.name }}</span>
                <span>{{ data.functionParameter[1].name }}：{{ (data.general.writablePointValueOption.dataType == '04' ||
            data.general.writablePointValueOption.dataType ==
            '05') ? data.general.writablePointValueLabel : data.general.writablePointValue
                    }}</span>），请输入操作密码执行指令！
                <a-input v-model:value="password" size="large" style="margin: 10px auto;width: 100%;" />
            </div>
            <div v-else>即将执行开启设备服务（<span v-for="(item, idx) in data.functionParameter" :key="idx"
                    style="margin-right: 10px;">{{
            item.name }}：<span v-if="item.value != undefined">{{ getValueLabel(item) }}</span></span>
                ），请输入操作密码执行指令！
                <a-input v-model:value="password" size="large" style="margin: 10px auto;width: 100%;" />
            </div>
        </div>
        <div v-if="visible3">
            <div v-for="item in doActionTip" :key="item"><span class="time">{{ item.result.split(' ')[0] }}</span><span>{{ item.result.split(' ')[1] }}</span></div>
            <div v-if="!showClose">... ...</div>
        </div>
        <template v-if="!visible2" #footer>
            <a-button v-if="visible" key="back" @click="handleClose">取 消</a-button>
            <a-button v-if="visible" key="submit" type="primary" @click="doAction">下 发</a-button>

            <a-button v-if="innerVisible" key="back" @click="handleClose">取 消</a-button>
            <a-button v-if="innerVisible" key="submit" type="primary" :loading="loading" @click="submit">确 认</a-button>

            <a-button v-if="visible3 && showClose" key="back" :disabled="!doActionFinish" @click="closeModal">关 闭</a-button>
        </template>
    </a-modal>

</template>

<script setup>
import md5 from "crypto-js/md5";
import { apiDeviceControlAll } from "@/api/IoT/device.js";
import { message } from "ant-design-vue";
import { ref, defineExpose, watch, reactive, defineEmits} from 'vue';
import { instanse } from "@/utils/ajax";
import http from "@/utils/http";
import dayjs from "dayjs";
const step1Visible = ref(false);
const visible2 = ref(false);
const visible = ref(false);
const innerVisible = ref(false);
const visible3 = ref(false);
const password = ref('');
const loading = ref(false);
const modalTitle = ref('');

const data = ref({})
const actionFlag = ref('')
const openModal = (flag) => {
    step1Visible.value = true;
    visible2.value = true;
    actionFlag.value = flag;
    modalTitle.value='批量控制-参数';
    initFormItems()
}

watch(()=>innerVisible.value, ()=>{
    password.value = ''
})

const emit = defineEmits(['close'])
const handleClose = ()=>{
    emit('close')
    visible.value = false
    innerVisible.value = false
}

const changePoint = (identifier) => {
    let { controlPointDesc, general } = data.value
    general.writablePointValueOption = controlPointDesc.find(e => e.identifier === identifier)
    general.writablePointValue = ''
};
const changePointValue = (identifier) => {
    let { general } = data.value
    let item = general.writablePointValueOption.valueDescription.find(e => e.key === identifier)
    general.writablePointValueLabel = item.value
};

const findValue = (arr, type, formatType) => {
    let item = arr.find(e => e.key === type)
    return formatType(item?.value)
}
const getValueLabel = (data) => {
    if (data.dataType == '04' || data.dataType == '05') {
        let item = data.valueDescription.find(e => {
            return e.key === data.value
        })
        return item?.value || data.value
    }
    return data.value
}

const doAction = async () => {
    visible.value = false
    if(actionFlag.value === 'need-passsword'){
        innerVisible.value = true
        modalTitle.value='批量控制-指令下发提醒';
    }
    if(actionFlag.value === 'pass'){
        submitAction()
    }   
}



const doActionTip = ref([])
const doActionFinish = ref(false)
const time1 = ref('')
const time2 = ref('')
const showClose = ref(false);
let tmr;
const submit = () => {
    if (!password.value.length) {
        message.error('请输入密码')
        return
    }
    submitAction(password.value)
}
const reset = ()=>{
    time2.value = dayjs().format('HH:mm:ss')
    doActionFinish.value = true
    loading.value = false
    clearTimeout(tmr)
}
const submitAction = (_password)=>{
    showClose.value = false;
    let m = {
        bizDeviceId: formData.bizDeviceId,
        identifier: data.value.identifier,
        intervalCode: formData.intervalCode,
    }
    if(_password){
        m.password = md5(_password).toString()
    }
    if (data.value.identifier === 'GeneralWritePoints') {
        m.functionParameters = [{
            identifier: data.value.functionParameter[0].identifier,
            value: data.value.general.writablePoint,
        }, {
            identifier: data.value.functionParameter[1].identifier,
            value: data.value.general.writablePointValue
        }]
    } else {
        m.functionParameters = data.value.functionParameter.map(e => {
            return {
                identifier: e.identifier,
                value: e.value
            }
        })
    }
    time1.value = dayjs().format('HH:mm:ss')
    tmr = setTimeout(() => {
        doActionFinish.value = true
    }, 60000)
    loading.value = true;
    instanse.ajax({
        type: "post",
        data: m,
        contentType:'application/json',
        url:`${http.axios.defaults.baseURL}` + "/cemp-monitor/device-monitor/batch-service-control",
        success(res) {
            try {
                if(res){
                    let strData = res.split('{').filter(item=>!!item).map(item=>'{'+item);
                    let result = strData.map(item=>JSON.parse(item));
                    let findOne = result&&result.find(item=>item.errorCode === '密码错误')
                    if(findOne){
                        message.error('密码错误22222');
                        reset()
                        return;
                    }
                    let findSuccess = result&&result.find(item=>item.result.split(' ')[1] === '批量指令下发完成');
                    if(findSuccess){
                        showClose.value = true;
                    }
                    innerVisible.value = false
                    visible3.value = true
                    doActionTip.value = result || [];
                    password.value = ''
                    data.value.general = {
                        writablePoint: '',
                        writablePointValue: '',
                        writablePointValueOption: {},
                        writablePointValueLabel: ''
                    }
                    data.value.functionParameter.forEach(e => e.value = '')
                    reset()
                }  
            } catch (error) {
                showClose.value = true;
                reset()
            }
        },
        error(){
            showClose.value = true;
            reset()
        }
    });

    // apiDeviceControlAll.control(m).then((res) => {
    //     let strData = res.split('{').filter(item=>!!item).map(item=>'{'+item);
    //     let strData4 = strData.map(item=>JSON.parse(item))
    //     if (res.errorCode == '密码错误') {
    //         return
    //     }
    //     innerVisible.value = false
    //     visible3.value = true
    //     modalTitle.value='批量控制-指令下发';
    //     doActionTip.value = strData4 || [];
    //     password.value = ''
    //     data.value.general = {
    //         writablePoint: '',
    //         writablePointValue: '',
    //         writablePointValueOption: {},
    //         writablePointValueLabel: ''
    //     }
    //     data.value.functionParameter.forEach(e => e.value = '')
    // }).finally(() => {
    //     time2.value = dayjs().format('HH:mm:ss')
    //     doActionFinish.value = true
    //     loading.value = false
    //     clearTimeout(tmr)
    // })
}

const closeModal = () => {
    doActionTip.value = ''
    doActionFinish.value = false
    visible3.value = false
    handleClose()
}

const formData = reactive({
    bizProjectId:'',
    productId:'',
    serveId:'',
    intervalCode:'',
    bizDeviceId:[]
})
const formItemsMap = reactive({
  projectId: {
    label: "选择项目",
    prop: "bizProjectId",
    type: "select",
    options: [],
    fieldNames: {
      label: "name",
      value: "bizProjectId",
    },
    required: true,
    requiredMessage: "请选择",
  },
  productName: {
    label: "选择产品",
    prop: "productId",
    type: "select",
    options: [],
    fieldNames: {
      label: "name",
      value: "id",
    },
    required: true,
    requiredMessage: "请选择",
  },
  serve: {
    label: "选择服务",
    prop: "serveId",
    type: "select",
    options: [],
    fieldNames: {
      label: "functionName",
      value: "id",
    },
    required: true,
    requiredMessage: "请选择",
  },
  time: {
    label: "下发间隔",
    prop: "intervalCode",
    type: "select",
    options: [
        {label:'1秒', value:0},
        {label:'2秒', value:1},
        {label:'5秒', value:2},
        {label:'10秒', value:3},
        {label:'30秒', value:4},
        {label:'60秒', value:5}
    ],
    required: true,
    requiredMessage: "请选择",
  },
  device: {
    label: "选择设备",
    prop: "bizDeviceId",
    type: "select",
    options: [],
    mode: "multiple",
    fieldNames: {
      label: "name",
      value: "bizDeviceId",
    },
    required: true,
    requiredMessage: "请选择",
  },
});

const formRef = ref(null);
const handleNext = async()=>{
    try {
        await formRef.value.formRef.validateFields();
        visible2.value = false;
        visible.value = true;
        modalTitle.value='批量控制-参数';
        let item = serverDatas.value;
        item.general = {
            writablePoint: '',
            writablePointValue: '',
            writablePointValueOption: {},
            writablePointValueLabel: ''
        }
        data.value = item

    } catch (error) {
        console.log("Failed:", error);
    }
   
}
const changeForm = async ({ prop }) => {
  //产品
  if (prop === "productId") {
    formData.serveId = '';
    initServiceList();
    initDeviceList()
  }
  //项目
  if (prop === "bizProjectId" ) {
    initDeviceList();
  }
  //服务
  if (prop === "serveId" ) {
    getServeParameter()
  }
};
const serverDatas = ref({});
const getServeParameter = ()=>{
    let findOne = formItemsMap.serve.options.find(item=>item.id == formData.serveId);
    if(findOne){
        serverDatas.value = findOne || {};
        modalTitle.value=`批量控制-${findOne.functionName}`;
    }
}
const initServiceList = ()=>{
    apiDeviceControlAll.service({productId: formData.productId}).then((res) => {
      formItemsMap.serve.options = res.result;
    });
}
const initDeviceList = ()=>{
    if(formData.productId && formData.bizProjectId){
        let bizProductId = '';
        let findOne = formItemsMap.productName.options.find(item=>item.id == formData.productId)
        if(findOne){
            bizProductId = findOne.bizId
        }
        formData.bizDeviceId = [];
        apiDeviceControlAll.device({bizProjectId: formData.bizProjectId, bizProductId}).then((res) => {
            formItemsMap.device.options = res.result;
        });
    }
   
}


const initFormItems = async () => {
    apiDeviceControlAll.project().then((res) => {
      formItemsMap.projectId.options = res.result;
    });
    apiDeviceControlAll.product().then((res) => {
      formItemsMap.productName.options = res.result || [];
    });
};


defineExpose({
    openModal,
});
</script>
<style lang='less' scoped>
.time {
    display: inline-block;
    margin-right: 10px;
    font-size: 12px;
    line-height: 30px;
    color: #999999;
    white-space: nowrap;
}
</style>